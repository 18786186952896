.timerItem {
  position: absolute;
  right: -225px;
  top: 250px;
}

.roomInfo {
  border-radius: 20px;
  box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
  background-color: #ffffff;
  display: flex;
  padding: 10px 0;
  padding-right: 50px;
  max-width: 311px;
  position: absolute;
  right: -225px;
  top: 50px;

  &__img {
    width: 138px;
    height: 138px;
    border-radius: 50%;
    margin-left: -60px;
    margin-right: 20px;
  }

  &__name {
    font-size: 17.5px;
    font-weight: bold;
    letter-spacing: 0.44px;
    color: #4c5788;
    margin: 5px 0;
  }

  &__community {
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #4c5788;
    margin: 5px 0;
  }

  &__price {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #4c5788;
    display: flex;
    align-items: center;
    margin-top: 10px;

    >svg {
      margin-right: 10px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    color: #4c5788;
    display: flex;

    >div {
      display: flex;
      align-items: center;
      margin: 10px 35px 10px 0;

      >svg {
        margin-right: 10px;
      }
    }
  }

  &__moreInfo {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-align: left;
    color: #f2b143;
    border-bottom: 1px solid #f2b143;
    text-transform: capitalize;
    line-height: 1.2;
    // margin-left: 10px;
  }

  &__moreInfo:hover {
    opacity: 0.8;
  }

  >div>a {
    text-decoration: none;
  }
}

.checkout {
  flex-direction: column;
  padding-bottom: 80px;
  padding-top: 50px;
  padding-right: 100px;

  &__lottie {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.52;
    letter-spacing: 0.8px;
    text-align: center;
    color: #4c5788;
    margin-bottom: 5px;
  }

  &__step1 {
    &__title {
      font-size: 21px;
      font-weight: bold;
      color: #4c5788;
      letter-spacing: 0.53px;

      >span {
        color: #293bff;
      }
    }

    &__text {
      font-size: 21px;
      font-weight: bold;
      text-align: center;
      color: #4c5788;
      letter-spacing: 0.53px;
      padding-bottom: 30px;
      border-bottom: 1px solid #9ba2c9;
    }

    &__text1 {
      font-size: 22px;
      font-weight: 300;
      text-align: justify;
      color: #4c5788;
      letter-spacing: 0.43px;
      margin-top: 30px;
      margin-bottom: 0px;
      line-height: 2.47;
    }

    &__text2 {
      font-size: 22px;
      font-weight: 300;
      text-align: justify;
      color: #4c5788;
      letter-spacing: 0.43px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 50px;
      line-height: 2.47;
      text-indent: -30px;
    }

    &__signtitle {
      font-size: 21px;
      font-weight: bold;
      line-height: 1.62;
      letter-spacing: 0.53px;
      color: #4c5788;
      max-width: 300px;
    }

    &__signItems {
      display: flex;
      justify-content: space-between;
    }

    &__signItem {
      // padding-right: 20px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      width: 45%;

      &__signature {
        position: absolute;
        left: 0;
        max-height: 70px;
        max-width: 150px;
        margin-top: -10px;

        @media screen and (max-width: 1000px) {
          margin-left: 30px;
        }
      }

      &__text {
        font-size: 13px;
        letter-spacing: 0.33px;
        color: #4c5788;
        line-height: 2.62;
        border-top: 1px solid #9ba2c9;
        margin-top: 5px;
      }

      &__textDate {
        font-size: 13px;
        letter-spacing: 0.33px;
        color: #4c5788;
        line-height: 2.62;
        margin: 0px;
        padding-top: 7px;
      }
    }

    &__signImg {
      height: 41px;
    }
  }

  &__step2 {
    margin-top: 100px;
  }
}

.preview2 {
  width: 100%;
  height: 816px;
  border-radius: 10px;
  box-shadow: 0 0 47.7px 14.3px rgba(210, 215, 255, 0.43);
  background: #ffffff;
  margin-bottom: 60px;
  margin-top: 20px;
}

.m-signature-pad {
  position: relative;
  font-size: 10px;
  height: 400px;
  width: 700px;
  // top: 50%;
  // left: 50%;
  // margin-left: -350px;// margin-top: -200px;
  // border: 1px solid #e8e8e8;
  background-color: #fff;
  // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.m-signature-pad--body {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 32px;
  border: 1px solid #f4f4f4;
}

.m-signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.m-signature-pad--footer {
  // position: absolute;
  // left: 20px;
  // right: 20px;
  // bottom: 20px;
  // height: 40px;
  display: flex;
  justify-content: center;
}

.m-signature-pad--footer .description {
  color: #c3c3c3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1.8em;
}

.m-signature-pad--footer .button {
  position: absolute;
  bottom: 0;
}

.m-signature-pad--footer .button.clear {
  left: 0;
}

.m-signature-pad--footer .button.save {
  right: 0;
}

@media screen and (max-width: 1024px) {
  .m-signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    // margin: 5%;
    margin: auto;
  }

  #github {
    display: none;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .m-signature-pad {
    // margin: 10%;
    margin: auto;
  }
}

@media screen and (max-height: 320px) {
  .m-signature-pad--body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 32px;
  }

  .m-signature-pad--footer {
    left: 20px;
    right: 20px;
    bottom: 4px;
    height: 28px;
  }

  .m-signature-pad--footer .description {
    font-size: 1em;
    margin-top: 1em;
  }
}

.roomInfoTimer {
  display: none;
}

.roomInfoTimer1 {
  display: block;
}

@media screen and (max-width: 1500px) {
  .roomInfoTimer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0;
    padding-left: 50px;
  }

  .roomInfoTimer1 {
    display: none;
  }

  .checkout {
    padding-right: unset;
  }
}

@media screen and (max-width: 500px) {
  .roomInfoTimer1 {
    display: none;
  }

  .roomInfoTimer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0;
    padding-left: unset;
  }

  .roomInfo {
    margin-top: 30px;
    padding-right: 5px;

    &__img {
      margin-left: -20px;
    }

    &__name {
      font-size: 15px;
    }

    &__price {
      font-size: 12px;
    }
  }

  .checkout {
    padding-right: unset;
    padding-top: unset;

    &__step1 {
      &__title {
        margin-bottom: 0px;
      }

      &__text1 {
        margin-top: 0px;
        font-size: 18px;
        line-height: 2;
      }

      &__text2 {
        margin-top: 0px;
        margin-left: 25px;
        font-size: 18px;
        line-height: 2;
      }

      &__signItems {
        flex-direction: column;
      }

      &__signItem {
        width: 100%;
      }
    }

    &__step2 {
      margin-top: 50px;
    }
  }

  .payment {
    >div {
      flex-direction: column;

      >label {
        width: 100%;
      }
    }
  }

  .cardcheck {
    >form>div>button {
      padding: 15px 30px;
      font-size: 25px;
    }

    &__text {
      font-size: 12.5px;
    }
  }
}