.roomdetail {
  margin-top: 30px;
  margin-bottom: 100px;
  padding: 50px;
  box-shadow: 0 0 47.7px 14.3px rgba(210, 215, 255, 0.43);
  background-color: #ffffff;
  border-radius: 10px;

  &__tab {
    // border-bottom: inset 2px #dddddd;
    box-shadow: 0 -10px 3px -8px #dddddd inset;
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 135px;

    @media screen and (max-width: 991px) {
      margin-bottom: 170px;
    }
  }

  &__title1 {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    @media screen and (max-width: 991px) {
      margin-bottom: 170px;
    }
  }

  &__info {
    width: 100%;
    border-radius: 10px;
    padding: 40px 0px;
    // box-shadow: 12.8px 2.3px 40.2px 8.8px rgba(24, 63, 255, 0.17);
    // background-color: #202dff;
    position: relative;

    h2 {
      margin: 0;
      font-family: Montserrat;
      font-size: 23.5px;
      font-weight: bold;
      letter-spacing: 1.2px;
      text-align: left;
      color: #ffffff;
    }

    &--item {
      margin: 15px 0;
      display: flex;
      align-items: center;
    }

    .icon {
      float: left;
      margin-right: 15px;
      width: 32px;
      height: 40px;
      fill: #4868ff;
      //     color: #ffffff;
    }

    p {
      margin: 0;
      margin-bottom: 10px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-align: left;
      color: #4c5788;
    }

    span {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: #4c5788;
    }

    &__slider {
      margin-top: -150px;

      >div>button {
        margin: 0 -25px;
      }

      img {
        width: 100%;
        height: 510px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0 0 20.8px 2px rgba(210, 215, 255, 0.22);
      }
    }
  }

  &__description {
    padding-top: 50px;
    // box-shadow: 0 0 47.7px 14.3px rgba(210, 215, 255, 0.43);
    // background-color: #ffffff;
    // border-radius: 0 0 10px 10px;

    p {
      margin: 0;
      margin-bottom: 10px;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      text-align: left;
      color: #4c5788;
    }

    span {
      font-family: Montserrat;
      font-size: 14.5px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.4px;
      text-align: left;
      color: #4c5788;
    }

    &__common {
      .icon {
        float: left;
        width: 50px;
        height: 42px;
        vertical-align: middle;
        margin-right: 30px;
        fill: #f2b143;
        color: #f2b143;
      }

      &--item {
        margin-top: 40px;
        display: flex;
        align-items: center;

        &:first-child {
          margin-top: 0;
        }

        div {
          float: left;
        }
      }
    }

    &__amenities {
      .icon {
        float: right;
        width: 26px;
        height: 26px;
        vertical-align: middle;
        margin-left: 30px;
      }

      .checked {
        fill: #74eaff;
        color: #74eaff;
      }

      .unchecked {
        fill: #f2b143;
        color: #f2b143;
      }

      &--item {
        // display: flex;
        // align-items: center;
        margin-top: 40px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &__description {
      margin-top: 30px;

      span {
        font-family: Open Sans;
        font-size: 16px;
        line-height: 2.19;
        letter-spacing: 0.8px;
      }

      img {
        margin: 10px;
        width: 279px;
        height: 194px;
        object-fit: cover;
        border-radius: 10px;
        box-shadow: 0 0 20.8px 2px rgba(210, 215, 255, 0.22);
      }
    }

    &--extra {
      margin-top: 55px;
    }
  }

  &__button {
    &__container {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      margin-bottom: 80px;
    }
  }

  .slick-dots {
    bottom: -30px;

    li button::before {
      width: 8px;
      height: 8px;
      border: #e6813b 1px solid;
      border-radius: 50%;
      opacity: 1;
      background: none;
      color: transparent;
    }

    li.slick-active button::before {
      background-image: linear-gradient(136deg, #f2b143 1%, #e6813b 99%);
      color: transparent;
      opacity: 1;
    }
  }
}

.favoriteButtonList {
  position: absolute;
  bottom: -30px;
  right: -250px;
}

@media screen and (max-width: 959px) {
  .roomdetail {
    &__info {
      width: unset;

      &__body {
        padding: 30px !important;
        margin-top: -70px !important;
      }
    }
  }

  .favoriteButtonList {
    display: flex;
    justify-content: space-between;
    position: unset;
    bottom: unset;
    right: unset;
  }
}

@media screen and (max-width: 767px) {
  .favoriteButtonList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .roomdetail__tab {
    button {
      span {
        font-size: 2.5vw !important;
      }
    }

    >div>div>div {
      >div {
        flex-wrap: wrap;
        justify-content: unset;
      }

      >span {
        display: none;
      }
    }
  }

  .favoriteButtonList {
    button {
      >span {
        font-size: 4.5vw !important;
      }
    }
  }

  .roomdetail__button__container {
    button {
      >span {
        font-size: 4.5vw !important;
      }
    }
  }
}

.favoriteButton {
  width: 71px;
  height: 71px;
  opacity: 0.8;
  box-shadow: 0 0 47.7px 14.3px rgba(210, 215, 255, 0.43);
  background-color: #ffffff;
  font-size: 23.5px;
  font-weight: bold;
  line-height: 1.94;
  letter-spacing: 0.59px;
  color: #4c5788;
  position: absolute;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  top: -35px;
  right: -35px;
}