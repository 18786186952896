.bookingStep {
  font-family: Montserrat;
  position: relative;
  padding-bottom: 150px;
  padding-top: 50px;

  >p {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #232651;
  }

  >div {
    margin-left: 200px;
    z-index: 1;
  }

  >img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

.stepItem {
  font-family: Montserrat;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
  }

  >p {
    color: #232651;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-weight: bold;

    >span {
      color: #286ff9;
    }
  }
}

.stepItem1 {
  font-family: Montserrat;
  margin-bottom: 3px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  >span {
    width: 95px;
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(193, 203, 249, 0.3);
    left: -150px;
    text-align: center;
    color: #606e97;
  }

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
    fill: #c1cbf9;
  }

  >p {
    color: #c1cbf9;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-weight: bold;
  }
}

.stepItem2 {
  font-family: Montserrat;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 372px;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(224, 232, 255, 0.7);
  padding: 5px 0;
  position: relative;

  >span {
    width: 95px;
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(193, 203, 249, 0.3);
    left: -155px;
    text-align: center;
    color: #606e97;
  }

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
  }

  >div {
    padding-top: 0.5rem;

    >span {
      color: #232651;
      letter-spacing: 0.01em;
      font-size: 14px;
      font-weight: bold;

      >span {
        color: #286ff9;
      }
    }

    >p {
      color: #606e97;
      letter-spacing: 0.01em;
      font-size: 12px;
      max-width: 292px;
      font-weight: 500;
      font-family: Montserrat;
      margin-top: 0.5rem;
    }
  }
}

.stepItem3 {
  font-family: Montserrat;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 372px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(244, 72, 72, 0.2);
  padding: 5px 0;
  position: relative;
  background-color: #fcfdfe;

  >span {
    width: 95px;
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(193, 203, 249, 0.3);
    left: -155px;
    text-align: center;
    color: #606e97;
  }

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
  }

  >div {
    padding-top: 0.5rem;

    >span {
      color: #232651;
      letter-spacing: 0.01em;
      font-size: 14px;
      font-weight: bold;
    }

    >p {
      color: #606e97;
      letter-spacing: 0.01em;
      font-size: 12px;
      max-width: 292px;
      font-weight: 500;
      line-height: 1.73;
      margin-top: 0.5rem;

      >span {
        color: #286ff9;
        text-decoration: underline;
      }
    }
  }
}

.MuiStepConnector-vertical {
  margin-left: 6px !important;
}

.fileuploader {
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0.43px;
  color: #0f0e40;
  text-decoration: underline;

  >span {
    margin-left: -160px;
    background-color: #ffffff;

    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }

  >span:hover {
    color: "gray";
  }

  >input {
    height: 6px;
    width: 160px;
  }
}

.checkoutpreview {
  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.72;
    letter-spacing: 0.85px;
    color: #0f0e40;
    text-align: center;
    margin: 100px 0 10px 0;
  }

  &__text {
    font-size: 18px;
    letter-spacing: 0.53px;
    color: #0f0e40;
    text-align: center;
    margin: 0px 0 20px 0;
  }

  &__item1 {
    display: flex;
    margin: 10px 0 !important;
    padding: 10px;
    // align-items: center;

    &__img {
      min-width: 143px;
      height: 143px;
      width: 143px;
      max-width: 166px;
      max-height: 166px;
      // min-width: 80px;
      // min-height: 80px;
      border-radius: 50%;
      z-index: 2;
    }

    &__data {
      z-index: 1;
      border-radius: 20px;
      box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
      background-color: #ffffff;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px 15px 20px;
      color: #232651;
      align-items: center;

      &__name {
        font-size: 23px;
        font-weight: bold;
        // color: #4c5788;
        margin-top: 0px;
        margin-bottom: 10px;

        >span>div {
          display: none;
        }
      }

      &__community {
        font-size: 16.5px;
        font-weight: 600;
        // color: #4c5788;
        margin-top: 0px;
        margin-bottom: 15px;
      }

      &__address {
        font-size: 16.5px;
        font-weight: 300;
        // color: #4c5788;
        margin-top: 0px;
        margin-bottom: 15px;
      }

      &__apartment {
        font-size: 16.5px;
        font-weight: 300;
        // color: #4c5788;
        margin-top: 0px;
        margin-bottom: 10px;
      }

      &__date {
        display: flex;
        align-items: center;

        >p {
          font-size: 17px;
          // color: rgba(89, 99, 144, 0.9);
          margin: 0 0 0 12px;
        }

        >svg {
          fill: #fdb451 !important;
        }
      }
    }

    &__price {
      display: flex;
      align-items: center;
      margin-right: 10px;

      >svg {
        fill: #fdb451 !important;
      }

      >p {
        font-size: 17px;
        color: #232651;
        font-weight: bold;
        margin: 0 0 0 7px;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;

      &__text {
        font-size: 16px;
        color: #4c5788;
        margin-top: -50px;
      }

      &__text1 {
        display: none;
        font-size: 16px;
        color: #4c5788;
        margin-top: -50px;
      }

      >button>span {
        margin-top: -3px;
        text-transform: capitalize;
      }

      &__moreInfo {
        font-size: 16.5px;
        font-weight: bold;
        color: #4c5788;
        border-bottom: 1px solid #4c5788;
        line-height: 1.2;

        @media screen and (max-width: 800px) {
          font-size: 14.5px;
        }
      }

      &__moreInfo:hover {
        font-size: 16.5px;
        font-weight: bold;
        color: #4c5788;
        opacity: 0.7;
      }
    }
  }
}

.booking {
  &__title {
    font-size: 34px;
    font-weight: bold;
    line-height: 1.72;
    letter-spacing: 0.85px;
    color: #0f0e40;
    text-align: center;
    margin: 100px 0 10px 0;
  }

  &__text {
    font-size: 21px;
    letter-spacing: 0.53px;
    color: #0f0e40;
    text-align: center;
    margin: 0px 0 20px 0;
  }

  &__item {
    // height: 163px;
    border-radius: 20px;
    box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 10px !important;

    &__body {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;

      >svg {
        min-width: 28px;
        width: 28px;
        height: 28px;
        fill: #0000ff;
      }

      >div {
        margin-left: 20px;

        >p {
          font-size: 21px;
          font-weight: 600;
          letter-spacing: 0.53px;
          color: #0f0e40;
          margin-top: 0px;
          margin-bottom: 17px;
        }

        >span {
          font-size: 17px;
          letter-spacing: 0.43px;
          color: #0f0e40;

          >a {
            margin-bottom: 23px;
            font-weight: 300;
            margin-top: 20px;
          }
        }
      }
    }

    &__button {
      width: 135px;
      height: 50px;
      border-radius: 24.5px;
      background-image: linear-gradient(136deg, #f2b143 1%, #e6813b 99%);
    }
  }
}

@media screen and (max-width: 600px) {
  .booking {
    &__item {
      flex-direction: column;
      justify-content: unset;
      padding: 0px;
      padding-bottom: 20px;

      &__body {
        justify-content: unset;

        >div {
          margin-left: 10px;

          >p {
            font-size: 15px;
          }

          >span {
            font-size: 14px;
          }
        }
      }

      >button {
        margin-left: 55px;
        min-width: unset;
        width: 103px;
        height: 37px;
      }
    }
  }

  #uploadedButton {
    margin-left: 55px;
    min-width: unset;
    width: 103px;
    height: 37px;
    cursor: pointer;
  }

  .checkoutpreview__item1 {
    &__img {
      width: 85px !important;
      height: 85px !important;
      margin: 10px;
      position: absolute;
      margin-left: 15px;
      min-width: unset !important;
    }

    &__data {
      display: block;
      margin-left: 5px;

      >div {
        width: 100%;
      }

      &__name {
        display: flex;
        justify-content: space-between;
        font-size: 20px;

        >span>div {
          display: flex;
        }
      }

      &__date,
      &__address,
      &__apartment {
        margin-left: -75px;
      }

      &__address {
        margin-bottom: 7px;
        margin-top: 30px;

        @media screen and (max-width: 380px) {
          margin-top: 0px;
        }
      }
    }

    &__pricediv {
      display: none;
    }

    &__buttons {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-left: -75px;
      width: 250px;

      >button {
        margin-top: 10px;
      }

      button:last-child {
        margin-left: -7px;
      }

      &__moreInfo {
        min-width: 190px;
      }

      &__moreInfo:hover {
        font-size: 14.5px;
      }

      &__text {
        display: none;
      }

      &__text1 {
        display: block;
        margin-top: 5px;
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .booking {
    &__title {
      font-size: 25px;
    }

    &__text {
      font-size: 15px;
    }
  }

  .checkoutpreview {
    &__title {
      font-size: 25px;
      margin-top: 50px;
    }

    &__text {
      font-size: 15px;
    }
  }
}