.blogItem {
  width: 350px;
  height: 596px;
  border-radius: 30px;
  box-shadow: 0 0 10.7px 7.3px rgba(210, 215, 255, 0.43);
  background-color: #ffffff;
  padding: 10px;

  &__imgItems {
    width: 100%;
    height: 53%;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 5px;
  }

  &__body {
    width: 100%;
    height: 47%;

    &__title {
      font-family: Montserrat;
      font-size: 18.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: 0.5px;
      text-align: left;
      color: #27346e;
      padding-left: 10px;
      margin: 0;
      height: 22%;
    }

    &__text {
      padding-left: 10px;
      font-family: Montserrat;
      font-size: 14.5px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: 0.4px;
      text-align: left;
      color: #4c5788;
      margin: 0 auto;
      height: 55%;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      &__location {
        display: flex;
        align-items: center;
      }

      &__icon {
        width: 27px;
        height: 38px;
        fill: #f2b143;
      }

      &__text {
        font-family: Montserrat;
        font-size: 14.5px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: 0.4px;
        text-align: left;
        color: #4c5788;
      }

      &__readmore {
        font-family: Montserrat;
        font-size: 14.5px;
        font-weight: 500;
        font-style: normal;
        text-decoration: underline;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: 0.4px;
        text-align: left;
        color: #27346e;
        cursor: pointer;
      }

      &__readmore:hover {
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .blogItem {
    width: 300px;
    height: 500px;
    border-radius: 30px;
    box-shadow: 0 0 10.7px 7.3px rgba(210, 215, 255, 0.43);
    background-color: #ffffff;
    padding: 10px;

    &__body {
      &__text {
        font-size: 14px;
        line-height: 1.8;
        margin-top: 5px;
      }
    }

    &__imgItems {
      width: 100%;
      height: 250px;
    }
  }
}

@media screen and (max-width: 330px) {
  .blogItem {
    width: 280px;
    height: 500px;
    border-radius: 30px;
    box-shadow: 0 0 10.7px 7.3px rgba(210, 215, 255, 0.43);
    background-color: #ffffff;
    padding: 10px;

    &__imgItems {
      width: 100%;
      height: 250px;
    }
  }
}