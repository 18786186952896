.wishlist {
  padding: 10px 0px;
  font-size: 14.5px;
  font-weight: 600;
  line-height: 1.69;
  letter-spacing: 0.73px;
  color: #596390;

  > p {
    text-align: center;
  }
}

.roomwishlist {
  margin: 50px 0px;
  padding: 0 20px;
  height: 205px;
  border-radius: 42px;
  box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
  background-color: #ffffff;
  display: flex;
  //  position: relative;

  &__nextStep {
    position: absolute;
    right: -200px;
    bottom: 0;
  }

  &__room {
    padding: 0 18px;
    position: relative;
    width: 165px;
    top: -28px;

    &__icon1 {
      width: 25px;
      height: 25px;
      fill: #f1b143;
      margin-left: 1px;
      // position: absolute;
      // top: 0;
      // right: 15%;
      // padding: 5px;
      // border: solid 1px #e4e7f6;
      // background-color: #ffffff;
      // border-radius: 50%;
    }

    &__icon2 {
      width: 15px;
      height: 15px;
      fill: #f1b143;
      // position: absolute;
      // top: 0;
      // left: 15%;
      // padding: 5px;
      // border: solid 1px #e4e7f6;
      // background-color: #ffffff;
      // border-radius: 50%;
    }

    &__img {
      width: 100%;
      height: 166px;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
      background-color: #f5f6fc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 600;
      color: rgba(89, 99, 144, 0.13);
      margin: 0 auto;
    }

    &__title {
      text-align: center;
      margin: 5px 0 4px 0px;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0.85px;
      color: #596390;
    }

    &__fav {
      border-radius: 50%;
      margin: 0 auto;
      width: 1px !important;
      height: 41px;
      box-shadow: 0 0 10px 2px rgba(208, 124, 29, 0.3);
      background-image: linear-gradient(136deg, #f2b143 1%, #e6813b 99%);
      display: flex;
      justify-content: center;
      align-items: center;

      > svg {
        margin-left: -40px;
        fill: #ffffff;
      }
    }
  }
}

.community {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__list {
    height: 225px;
    width: 100%;
    text-align: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;

    > div {
      .slick-list {
        padding: unset !important;
      }
    }

    @media screen and (max-width: 1200px) {
      width: unset;
      height: unset;

      > div > div > div {
        .slick-current {
          > div {
            .community__item {
              margin: 10px -20px !important;
            }
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      padding: 0 15%;
    }

    > div > div > div {
      .slick-active {
        > div {
          .community__item {
            display: flex !important;
          }
        }
      }

      .slick-current {
        > div {
          .community__item {
            margin: 10px 0px !important;

            &__image {
              width: 175px;
              height: 175px;
              border: solid 3px white;
            }

            &__container {
              display: flex;
              justify-content: center;
              flex-direction: column;
              width: 100%;
              max-width: 300px;
              height: 205px;
              border-radius: 30px;
              box-shadow: 0 0 7.1px 2.9px rgba(37, 60, 158, 0.46);
              background-image: linear-gradient(357deg, #4868ff, #0000ff 99%);
              margin-left: -70px;
              padding-left: 80px;
              padding-right: 35px;
              z-index: -1;
            }

            &__name {
              font-family: Montserrat;
              font-size: 24px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.15;
              letter-spacing: 0.44px;
              text-align: left;
              color: #ffffff;
              margin-top: 0px;
              margin-bottom: 0px;
            }

            &__text {
              font-family: Montserrat;
              font-size: 24px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.25;
              letter-spacing: 0.44px;
              text-align: left;
              color: #ffffff;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 18px;
  }

  &__item {
    display: flex !important;
    align-items: center;
    margin: 30px 10px;
    width: 400px !important;

    &__container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      max-width: 215px;
      height: 148px;
      border-radius: 30px;
      box-shadow: 0 0 27px 0px rgba(210, 215, 255, 0.21);
      background-color: #ffffff;
      margin-left: -60px;
      padding-left: 70px;
      padding-right: 35px;
      z-index: -1;
    }

    // &__imageDiv {
    // padding: 50px;
    // border-radius: 50%;
    // border: dashed 1px #f2b143;
    // border: solid 3px white;
    //  }

    &__image {
      width: 125px;
      border-radius: 50%;
    }

    &__name {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: 0.44px;
      text-align: left;
      color: #596390;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    &__text {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: 0.44px;
      text-align: left;
      color: #596390;
      margin: 3px 0;
    }
  }

  &__button {
    &__container {
      display: flex;
      justify-content: center;
      margin-top: 70px;
    }
  }
}

@media screen and (max-width: 900px) {
  .community {
    margin-bottom: 80px;

    &__list {
      display: block !important;

      > div {
        max-width: unset;

        > a {
          padding-left: unset;
          padding-right: unset;

          > img {
            width: 140px;
          }
        }
      }
    }

    &__item__container {
      height: 120px;
      padding-right: 10px;
      padding-left: 115px;

      > span {
        margin-right: 10%;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 500px) {
          text-align: end;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .community {
    margin-bottom: 0px;

    &__title {
      margin-top: 25px;
    }
  }
}
