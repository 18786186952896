.payment {
  display: flex;
  flex-direction: column;

  &__text1 {
    font-size: 26px;
    text-align: center;
    font-weight: bold;
  }

  > svg {
    margin: 0 auto;
    max-width: 367px;
    max-height: 242px;
    width: 100%;
    padding: 1rem;
  }

  &__text2 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: #f55859;
  }

  &__text3 {
    font-size: 20px;
    text-align: center;
    color: #232651;
    max-width: 500px;
    margin: 0 auto;
    line-height: 173.4%;
    letter-spacing: 0.01em;

    > span {
      color: #000;
      font-weight: 600;
    }
  }
}
