.timer {
  padding: 15px 0px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
  max-width: 311px;
  width: 300px;
  display: flex;
  justify-content: center;

  &__time {
    font-size: 16.5px;
    font-weight: 600;
    letter-spacing: 0.41px;
    color: #4c5788;
    margin: 0px;
    width: 215px;

    > b {
      font-size: 40px;
    }

    > span {
      font-size: 42px;
      font-weight: bold;
      letter-spacing: 1.05px;
      color: #2434ff;
      text-shadow: 0 0 78.1px rgba(37, 60, 158, 0.24);
      width: 60px !important;
    }
  }

  &__text {
    font-size: 12.5px;
    font-weight: 300;
    letter-spacing: 0.31px;
    text-align: left;
    line-height: 1.5;
    color: #4c5788;
    margin-bottom: 0px;
    margin-top: 10px;

    > span {
      color: #f4944e;
      font-weight: bold;
    }
  }
}
