.faqItem {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.checkIcon {
  width: 35px !important;
  height: 35px;
  border-radius: 18px;
  background-image: linear-gradient(357deg, #4868ff, #0000ff 99%);
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkIconText {
  font-family: Montserrat;
  font-size: 25.5px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.16;
  text-align: center;
  letter-spacing: 0.6px;
  color: #ffffff;
}

.question {
  font-family: Montserrat;
  font-size: 25.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  text-align: left;
  letter-spacing: 0.6px;
  color: #27346e;
  margin: 0px auto;
  margin-left: 18px;
}

.answer {
  font-family: Montserrat;
  font-size: 18.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  text-align: left;
  letter-spacing: 0.5px;
  color: #4c5788;
  margin: 0px auto;
  margin-left: 55px;
  // margin-top: -15px;
  padding-left: 19px;
  border-left: 1px solid #ffa800;
}
