.room {
	margin-top: 50px;

	.description {
		text-align: center;
	}

	.room {
		pointer-events: none;
	}
}