.end {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  max-height: 650px;

  &__body {
    width: 50%;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.85px;
    text-align: center;
  }

  &__text {
    font-size: 20px;
    letter-spacing: 0.61px;
    line-height: 1.06;
    text-align: center;
  }

  &__text1 {
    font-size: 18px;
    letter-spacing: 0.61px;
    line-height: 1.06;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    >div {
      display: flex;
      flex-direction: row;
      justify-content: center;

      >span {
        align-self: center;
        font-weight: bold;
        margin-top: 3px;
      }
    }
  }

  &__img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  &__frame {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    padding: 2rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    background-color: #fcfdfe;
    box-shadow: 0px 4px 40px rgba(224, 232, 255, 0.5);
    border-radius: 20px;

    >svg {
      width: 175px;
      height: 151px;
      align-self: center;
    }

    >p {
      text-align: center;
      font-size: 20px;
      line-height: 1.5;
      max-width: 350px;
      margin: 0 auto;
      margin-top: 1.5rem;
      letter-spacing: 0.61px;

      >span {
        font-weight: bold;
        color: #0a49fa;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .end {
    flex-direction: column;

    &__title {
      font-size: 25px;
      text-align: center;
    }

    &__text {
      font-size: 17px;
      text-align: center;
    }

    &__body {
      width: 100%;
    }

    &__img {
      width: 100%;
      margin: 30px 0;
    }
  }
}