.checkoutPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  &__title {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.52;
    letter-spacing: 0.8px;
    text-align: left;
    color: #4c5788;
    margin-top: 50px;
    margin-bottom: 5px;
  }

  &__description {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.9px;
    text-align: center;
    color: #596390;
  }

  &__cardGrid {
    width: 100%;
    height: 500px;
    justify-content: space-between;
  }
}

.preview2 {
  width: 100%;
  height: 816px;
  border-radius: 10px;
  box-shadow: 0 0 47.7px 14.3px rgba(210, 215, 255, 0.43);
  background: #ffffff;
  margin-bottom: 60px;
  margin-top: 20px;
}