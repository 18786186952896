.resetPassword {
  height: 83vh;
  max-width: 600px;
  margin: 0 auto;
  justify-content: center;

  &__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.52;
    letter-spacing: 0.8px;
    color: #4c5788;
  }

  &__errorMsg {
    font-size: 15.5px;
    line-height: 2.94;
    letter-spacing: 0.8px;
    color: #e7863c;
    margin: 0;
  }
}

.space {
  height: 50px;
}