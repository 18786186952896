.payment {
  padding-top: 10px;

  >div {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;

    input {
      width: 100%;
      display: block;
      margin: 9px 0 5px 0;
      font-size: 15.5px;
      letter-spacing: 1.5px;
      //   max-width: 400px;
      padding: 5px 0px;
      // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      //   rgba(0, 0, 0, 0.196078) 0px 1px 0px;
      border: 0;
      outline: 0;
      // border-radius: 5px;
      border-bottom: solid 1px #d9dcee;
      // background: #fafbff;
      // font-size: 18px;
      background: transparent;
      color: #596390;
      // letter-spacing: 0.15em;
    }

    label {
      width: 45%;
      display: block;
      color: #596390;
      font-weight: bold !important;
      letter-spacing: 0.9px;
      font-size: 18px;
      margin-bottom: 9px;

      @media screen and (min-width: 768px) {
        // margin: 8px 5px;
      }

      .StripeElement {
        display: block;
        margin: 9px 0 5px 0;
        //   max-width: 400px;
        padding: 5px 0px;
        // letter-spacing: 0.125em;
        // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        //   rgba(0, 0, 0, 0.196078) 0px 1px 0px;
        // border: 0;
        outline: 0;
        // border-radius: 5px;
        border-bottom: solid 1px #d9dcee;
        // background: #fafbff;
        font-size: 18px;
        color: #424770;
        letter-spacing: 0.15em;
        height: 21px;
      }

      // input {
      //   font-size: 18px !important;
      //   letter-spacing: 0.1em !important;
      // }
    }
  }
}

.cardcheck {
  >form {
    width: 100%;
  }

  &__text {
    font-size: 18.5px;
    font-weight: 300;
    line-height: 1.69;
    letter-spacing: 0.36px;
    text-align: center;
    color: #4c5788;
    margin: 0px auto;

    >span {
      color: #e7863c;
      font-weight: bold;
    }
  }
}