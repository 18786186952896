.forgot {
  height: 100vh;

  &__title {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    margin-bottom: 50px;
  }

  &__button {
    &__container {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
}

.forgotForm {
  width: 90% !important;
  margin: 0 auto;
}

.forgotPassword {
  h1 {
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }
}

.img-lock {
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

// .forgotPassword-input {}
// .bottomBtn {
// 	position: fixed;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	padding: 30px 0px;
// 	text-align: center;
// 	font-size: 24px;
// 	border: 0px;
// }

@media screen and (max-width: 600px) {
  .forgot {
    height: 600px;
  }
}
