.selectRoom {
  padding: 60px 120px 40px 120px;

  &__text {
    margin: 0 auto;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.53px;
    color: #4c5788;
    text-align: center;
    max-width: 300px;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 35px 0 55px 0;

    &__img {
      width: 255px;
      height: 161px;
      object-fit: cover;
      margin-right: 35px;
      border-radius: 10px;
    }

    &__detail {}

    &__name {
      font-size: 17px;
      font-weight: bold;
      color: #4c5788;
    }

    &__price {
      display: flex;
      align-items: center;

      >svg {
        width: 26px;
        fill: #0000ff;
        margin-right: 15px;
      }

      >p {
        margin: 0px;
        font-size: 17px;
        font-weight: 600;
        color: rgba(76, 87, 136, 0.9);
      }
    }

    &__date {
      display: flex;
      align-items: center;
      margin-top: 15px;

      >svg {
        width: 25px;
        fill: #0000ff;
        margin-right: 15px;
      }

      >p {
        margin: 0px;
        font-size: 17px;
        font-weight: normal;
        color: rgba(76, 87, 136, 0.9);
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;

    >button {
      width: unset !important;
      margin: 0px 10px;
    }
  }

  #backBtn1 {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .selectRoom {
    padding: 60px 30px 40px 30px;
  }
}

@media screen and (max-width: 800px) {
  #backBtn {
    display: none;
  }

  .selectRoom {
    padding: 60px 30px 40px 30px;

    #backBtn1 {
      display: block;
      padding: 10px 20px;
    }

    &__body {
      flex-direction: column;
      padding-bottom: 40px;

      &__img {
        margin: 0 auto;
      }

      &__detail {
        margin: 0 auto;
        padding-top: 30px;
      }
    }

    &__buttons {
      padding: 10px 20px;

      >button {
        padding: 10px 20px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .dialog {
    >div>div {
      margin: 10px;
    }

    .signup {
      padding: 10px;
    }
  }

  .selectRoom {
    padding: 40px 15px 20px 15px;

    &__body {
      &__name {
        font-size: 13px;
      }
    }

    &__text {
      font-size: 15px;
    }

    &__buttons {
      padding: 0px;
    }
  }
}