.userPasswordModify {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.resetPasswordInput {}

// .bottomBtn {
// 	position: fixed;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	padding: 30px 0px;
// 	text-align: center;
// 	font-size: 24px;
// 	border: 0px;
// }