.landingCommunity {
  width: 100%;

  &__section1 {
    //   z-index: -1;

    &__img {
      // height: 855px;
      width: 100%;
      margin-top: -200px;
      margin-left: -30px;
      // display: none;
    }

    &__body {
      width: 50%;
      text-align: center;
      // position: absolute;
      // bottom: 0;
      margin-top: -8%;
      margin-left: 52%;
      margin-bottom: 100px;

      &__title1 {
        font-size: 50px;
        text-align: left;
        margin-left: 100px;
        font-family: Montserrat;
        // font-weight: bold;
        // font-style: italic;
        letter-spacing: 0.8px;
        color: #ec9a3f;
        margin: 0 auto;
      }

      &__bigTitle {
        width: 200px;
        font-size: 80px;
        margin: 0 auto;
        font-family: Montserrat;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 1;
        // padding-bottom: 100px;
        color: #27346e;
        position: relative;

        > p {
          line-height: 1.2;
          margin-bottom: 0px;
        }

        > span {
          color: grey;
          font-size: 400px;
          position: absolute;
          bottom: -80px;
          left: -75px;
          z-index: -1;
          opacity: 0.5;
        }
      }

      &__text {
        // margin: 0 auto;
        font-family: Montserrat;
        font-size: 25.5px;
        font-weight: normal;
        letter-spacing: 0.6px;
        color: #4c5788;
      }

      &__blueButton {
        width: 176px;
        height: 66px;
        border-radius: 33px;
        box-shadow: 0 0 55px 5.9px rgba(37, 60, 158, 0.46);
        background-image: linear-gradient(357deg, #4868ff, #0000ff 99%);
        align-items: center;
        display: flex;
        justify-content: center;
        font-family: Montserrat;
        font-size: 20.5px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 0.5px;
        color: #ffffff;
        margin: 0 auto;
      }
    }
  }

  // &__section2 {
  //   margin-top: 110px;
  //   margin-bottom: 50px;

  //   &__images {
  //     margin-top: 50px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   &__img {
  //     padding: 20px;
  //     width: 500px;
  //     height: 420px;
  //   }
  // }

  &__section2 {
    position: relative;
    height: 790px;

    &__img {
      width: 100%;
      height: 820px;
      position: absolute;
      transform: scale(1, 1);
      background-image: url("/assets/background1.png");
      background-position: top;
      z-index: -1;
    }

    &__img1 {
      width: 231px;
      height: 307px;
      position: absolute;
      transform: scale(1, 1);
      background-image: url(../../assets/landing/8.svg);
      background-repeat: no-repeat;
      right: 10px;
      top: 0px;
      z-index: 0;
    }

    &__img2 {
      width: 250px;
      height: 301px;
      position: absolute;
      transform: scale(1, 1);
      background-image: url(../../assets/landing/7.svg);
      background-repeat: no-repeat;
      left: 10px;
      bottom: -200px;
      z-index: 0;
    }

    &__body {
      margin-top: 150px;
      margin-bottom: 50px;
      height: 200px;
      display: flex;
      align-items: flex-end;
    }

    &__title {
      font-family: Montserrat;
      font-size: 47.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      line-height: 1.69;
      text-align: center;
      color: #27346e;
      margin: 0 auto;
    }

    &__text {
      width: 556px;
      font-family: Montserrat;
      font-size: 18.5px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      line-height: 2;
      text-align: center;
      color: #4c5788;
      margin: 0 auto;
    }

    &__blog {
      height: 510px;
      width: 100%;
      // background-image: url("/assets/background1.png");
      // background-size: inherit;
      // background-position-x: center;
      // z-index: -2;
      text-align: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      // transform: scale(-1, 1);
    }
  }

  &__section3 {
    position: relative;

    &__body {
      margin-top: 0px;
      margin-bottom: 50px;
    }

    &__title {
      font-family: Montserrat;
      font-size: 47.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      line-height: 1.69;
      text-align: center;
      color: #27346e;
      margin: 0 auto;
      padding-top: 50px;
    }

    &__centerNo {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: -1;
      padding: 15px 0;
      height: 90%;
    }

    &__center {
      // height: 2600px;
      width: 0px;
      border: dashed 1px rgba(0, 0, 255, 0.5);
    }

    &__items {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    &__video {
      padding-left: 50px;
      padding-right: 50px;
      width: 50%;
      height: 420px;
    }

    &__img1 {
      width: 250px;
      height: 191px;
      position: absolute;
      bottom: -200px;
      right: 100px;
      background-image: url(../../assets/landing/img1.svg);
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &__item {
      //    padding-right: 50px;
      //    padding-left: 50px;
      //  width: 500px;
      height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__body {
        width: 520px;
      }
    }
  }

  &__section4 {
    margin-top: 100px;
    padding-top: 100px;
    padding-bottom: 300px;
    position: relative;
    overflow-y: hidden;

    @media screen and (max-width: 600px) {
      margin-top: 50px;
      padding-top: 50px;
      padding-bottom: 200px;
    }

    &__img2 {
      width: 508px;
      height: 260px;
      position: absolute;
      bottom: 0px;
      left: 100px;
      background-image: url(../../assets/landing/img2.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (max-width: 600px) {
        width: 100%;
        height: 160px;
        left: unset;
      }
    }

    &__backgroundImg {
      position: absolute;
      top: -200px;
      height: 1100px;
      width: 100%;
      background-image: url("/assets/background4.png");
      background-size: inherit;
      background-position-x: center;
      background-repeat: no-repeat;
      z-index: -1;

      @media screen and (max-width: 600px) {
        top: -300px;
      }
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;

      &__img {
        height: 466px;
      }
    }
  }

  &__section5 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;

    &__body {
      width: 700px;
      height: 580px;
      // position: absolute;// bottom: 0;
      background-image: url(../../assets/landing/11.svg);
      background-size: inherit;
      background-position-x: center;
      z-index: -2;
      padding-top: 50px;
      background-repeat: no-repeat;

      &__title {
        font-family: Montserrat;
        font-size: 28.5px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.7px;
        line-height: 1.5;
        text-align: center;
        color: #27346e;
      }

      &__text {
        // width: 335px;
        padding-right: 180px;
        padding-left: 150px;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.5px;
        line-height: 1.8;
        text-align: center;
        color: #4c5788;
      }
    }

    &__mobile {
      display: flex;
      align-items: center;
      margin-left: 40px;

      &__img {
        height: 630px;
      }
    }
  }

  &__section6 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;

    &__body {
      width: 969px;
      height: 270px;
      border-radius: 30px;
      box-shadow: 0 0 47.7px 14.3px rgba(210, 215, 255, 0.43);
      background-color: #ffffff;
      padding-top: 70px;
    }

    &__title {
      font-family: Montserrat;
      font-size: 47.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      line-height: 1.23;
      text-align: center;
      color: #27346e;
      margin: 0 auto;
    }

    &__text {
      font-family: Montserrat;
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.6px;
      line-height: 2;
      text-align: center;
      color: #27346e;
      margin: 0 auto;
    }

    &__buttonApply {
      margin: 0 auto !important;
      width: 231px;
      height: 85px;
      border-radius: 42.1px !important;
      background-image: linear-gradient(136deg, #f2b143 1%, #e6813b 99%);
      box-shadow: rgba(230, 129, 59, 0.5) 0px 0px 40px 1px;
      font-size: 26.5px !important;
      font-family: Montserrat;
      font-weight: bold;
      letter-spacing: 1.3px;
      text-align: center;
      color: #ffffff !important;
      cursor: pointer;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 20px !important;
    }
  }

  &__section7 {
    padding-bottom: 200px;
    overflow-x: hidden;

    &__img {
      width: 100%;
      height: 750px;
      position: absolute;
      bottom: 0;
      transform: scale(-1, 1);
      background-image: url("/assets/background1.png");
      background-position: top;
    }

    &__body {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &__title {
      font-family: Montserrat;
      font-size: 47.5px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      line-height: 1.69;
      text-align: center;
      color: #27346e;
      margin: 0 auto;
    }

    &__text {
      width: 556px;
      font-family: Montserrat;
      font-size: 18.5px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.5px;
      line-height: 2;
      text-align: center;
      color: #4c5788;
      margin: 0 auto;
    }

    &__blog {
      height: 650px;
      width: 100%;
      // background-image: url("/assets/background1.png");
      // background-size: inherit;
      // background-position-x: center;
      // z-index: -2;
      text-align: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      // transform: scale(-1, 1);
    }
  }
}

@media screen and (max-width: 1200px) {
  .landingCommunity {
    &__section1 {
      &__img {
        margin-top: -10%;
        margin-left: unset;
      }

      &__body {
        margin-bottom: 0px;
        margin-top: -20%;
        margin-left: 0%;
        width: 100%;

        &__title1 {
          font-size: 4vw;
        }

        &__bigTitle {
          font-size: 5vw;
          padding-left: 100px;

          > span {
            color: grey;
            font-size: 260px;
            position: absolute;
            bottom: -80px;
            left: 100px;
            z-index: -1;
            opacity: 0.5;
          }
        }

        &__text {
          font-size: 3.7vw;
        }
      }
    }

    &__section2 {
      //   padding-bottom: 150px;

      &__body {
        margin-top: unset;
      }

      &__blog {
        width: unset;
        height: unset;

        @media screen and (max-width: 800px) {
          padding: 0 15%;
        }
      }
    }

    // &__section2 {
    //   margin-top: unset;

    //   div:first-child {
    //     font-size: 3vw;
    //   }

    //   &__images {
    //     margin-top: unset;
    //   }

    //   &__img {
    //     height: unset;
    //     width: 40%;
    //   }
    // }

    &__section3 {
      position: relative;

      &__img {
        width: 42%;
        height: unset;
        padding: 0 5%;
      }

      &__item {
        width: 45%;
        height: unset;

        &__body {
          width: unset;

          div:first-child {
            > svg {
              width: 18%;
              height: 18%;
            }

            > div {
              div:first-child {
                font-size: 2.9vw;
              }

              div:nth-child(2) {
                font-size: 2.6vw;
              }
            }
          }

          div:nth-child(2) {
            font-size: 1.3vw;
          }
        }
      }

      &__items {
        padding: 15px;
      }

      &__centerNo {
        height: 91%;
      }

      &__center {
        height: 100%;
        position: relative;

        > div {
          position: absolute;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          margin-left: -26px;
        }
      }
    }

    &__section4 {
      .personInfo {
        &__imgItem {
          width: 88px;
          height: 88px;
        }
      }

      &__body {
        padding: 20px 0;

        &__img {
          // width: 40%;
          // height: unset;
          display: none;
        }

        > div {
          // margin-left: -25px;
          width: 530px;
        }
      }
    }

    &__section5 {
      &__body {
        height: unset;
        width: 60%;
        padding: 0px;

        &__title {
          margin-top: 20px;
          margin-bottom: 5px;
          height: 10%;
        }

        &__text {
          margin: 0;
          font-size: 2vw;
          height: 35%;
          padding-right: 25%;
          padding-left: 20%;
        }
      }

      &__mobile {
        width: 30%;
        align-items: flex-start;

        &__img {
          width: 100%;
          height: unset;
        }
      }
    }

    &__section6 {
      &__body {
        width: 85%;
        padding: 5% 0;
        height: unset;
      }

      &__title {
        font-size: 4vw;
      }

      &__text {
        font-size: 2vw;
      }
    }

    &__section7 {
      overflow-x: hidden;
      padding-bottom: 150px;

      &__blog {
        width: unset;
        height: unset;

        @media screen and (max-width: 800px) {
          padding: 0 15%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .landingCommunity {
    &__section1 {
      margin-top: -70px;
      overflow-x: hidden;

      &__img {
        width: 130%;
        margin-top: -3%;
        margin-left: -3%;
      }

      &__body {
        width: 100%;
        margin-top: -15%;
        margin-bottom: 15%;
        margin-left: unset;

        &__title1 {
          font-size: 7vw;
          text-align: center;
        }

        &__bigTitle {
          font-size: 8vw;
          width: 200px;

          > p {
            width: 150px;
            padding-left: 55%;
            margin-bottom: 0px;

            @media screen and (max-width: 600px) {
              padding-left: 40%;
            }
          }

          > span {
            font-size: 250px;
            right: -45%;
            bottom: -100px;

            @media screen and (max-width: 600px) {
              font-size: 200px;
              right: -30%;
              bottom: -90px;
            }
          }
        }

        &__text {
          font-size: 5vw;
          padding: 0 20%;
        }
      }
    }

    // &__section2 {
    //   margin-top: unset;

    //   div:first-child {
    //     font-size: 5vw;
    //   }

    //   &__images {
    //     margin-top: unset;

    //     img:first-child {
    //       width: unset;
    //       height: 250px;
    //     }

    //     img:nth-child(2) {
    //       display: none;
    //     }
    //   }
    // }

    &__section2 {
      height: 650px;

      &__body {
        margin: 50px 5px;
        height: unset;
      }

      &__img {
        display: none;
      }

      &__text {
        width: 100%;
        font-size: 5vw;
      }

      &__img1 {
        display: none;
      }

      &__img2 {
        display: none;
      }
    }

    &__section3 {
      &__centerNo {
        display: none;
      }

      &__items {
        display: block;
      }

      &__img {
        width: 100%;
        padding: 0px;
      }

      &__img1 {
        display: none;
      }

      &__item {
        width: 100%;
        justify-content: unset;

        &__body {
          &__number {
            width: 40px !important;
            height: 40px !important;
            border-radius: 20px;
            margin-left: 0px;
            margin-right: 15px;
          }

          div:first-child {
            align-items: center;

            > svg {
              width: 42px;
              height: 42px;
            }

            > div {
              div:first-child {
                font-size: 21px;
              }

              div:nth-child(2) {
                font-size: 18.5px;
              }
            }
          }

          div:nth-child(2) {
            font-size: 3vw;
          }
        }
      }
    }

    &__section4 {
      .personText {
        font-size: 3vw;
      }

      .personInfo {
        &__imgItem {
          width: 88px;
          height: 88px;
        }
      }

      &__body {
        margin-top: 20px;

        > div {
          width: 80%;
        }
      }
    }

    &__section5 {
      display: block;

      &__body {
        width: 100%;
        height: 410px;
        padding: 0px;

        &__title {
          margin-top: unset;
          padding-top: 20px;
          margin-bottom: 15px;
        }

        &__text {
          margin: 0;
          font-size: 2.4vw;
          height: 35%;
          padding-right: 25%;
          padding-left: 20%;
        }
      }

      &__mobile {
        width: unset;
        margin: unset;

        &__img {
          height: 700px;
          margin-top: 30px;
        }
      }
    }

    &__section6 {
      margin-top: 0px;
      margin-bottom: 50px;

      &__body {
        width: 90%;
        padding: 5% 0;
        height: unset;
        background-color: unset;
        box-shadow: unset;
        border-radius: unset;
      }

      &__title {
        font-size: 9vw;
      }

      &__text {
        font-size: 4vw;
      }
    }

    &__section7 {
      &__body {
        margin: 50px 5px;
      }

      &__img {
        display: none;
      }

      &__text {
        width: 100%;
        font-size: 5vw;
      }
    }
  }
}
