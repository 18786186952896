.signature {
  padding: 50px 20px 20px 20px;

  &__text {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.43px;
    color: #4c5788;
    text-align: center;
    margin: 0px;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 0px 0;

    &__img {
      width: 255px;
      height: 161px;
      object-fit: cover;
      margin-right: 35px;
      border-radius: 10px;
    }

    &__name {
      font-size: 17px;
      font-weight: bold;
      color: #4c5788;
    }

    &__price {
      display: flex;
      align-items: center;

      >svg {
        width: 26px;
        fill: #0000ff;
        margin-right: 15px;
      }

      >p {
        margin: 0px;
        font-size: 17px;
        font-weight: 600;
        color: rgba(76, 87, 136, 0.9);
      }
    }

    &__date {
      display: flex;
      align-items: center;
      margin-top: 15px;

      >svg {
        width: 25px;
        fill: #0000ff;
        margin-right: 15px;
      }

      >p {
        margin: 0px;
        font-size: 17px;
        font-weight: normal;
        color: rgba(76, 87, 136, 0.9);
      }
    }
  }

  &__buttons1 {
    display: flex;
    justify-content: center;

    >button {
      // width: unset;
      margin: 0px 10px;
    }
  }
}