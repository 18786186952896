.logoutSuccess {
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	
	h1 {
		font-size: 2.8rem;
		font-weight: bold;
		text-align: center;
		color: white;
	}
}

.logoutSuccess-title {
	margin: 100px 0px;
	color: white;
}

// .bottomBtn {
// 	position: fixed;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	padding: 30px 0px;
// 	text-align: center;
// 	font-size: 1.8rem;
// 	border: 0px;
// }