.signupForm {
  display: flex;
  flex-direction: row;
  width: 1000px;
  // height: 100vh;
  padding: 0px 0 !important;
  border-radius: 20px;
  background-color: ghostwhite;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 150px;
}

.signUp {
  display: flex;
  flex-direction: row;
  width: 1000px;
  height: 680px;
  padding: 0px !important;

  &__close {
    width: 50px;
    height: 50px;
    position: absolute !important;
    right: 0;
    top: 0;
  }

  &__buttons {
    width: 45%;
    text-align: center;
    padding-bottom: 50px;

    &__title {
      font-family: Montserrat;
      font-size: 29px;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.7px;
      color: #4c5788;
      margin-bottom: 50px;
      margin-top: 100px;
    }

    &__button span {
      line-height: normal !important;
      justify-content: flex-start;
      text-transform: none;
    }

    &__button span img {
      margin-left: 30px;
      margin-right: 5px;
    }

    &__button {
      width: 382px;
      height: 61px;
      padding: 0 !important;
      // padding-left: 32px;
      margin-right: 40px;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 30px !important;
      cursor: pointer;
      margin-bottom: 25px !important;

      &__icon {
        width: 27px;
        height: 27px;
        fill: #ffffff;
      }

      &__text {
        margin-left: 11px;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #ffffff;
        margin: 0px;
      }
    }
  }

  &__form {
    width: 55%;
    text-align: center;
    // padding-top: 50px;
    // padding-bottom: 50px;
    box-shadow: inset 40px 0px 41px -25px rgba(210, 215, 255, 0.43);
    display: flex;
    justify-content: center;
    align-items: center;

    &__background {
      padding: 30px;
      width: 78%;
    }
  }

  &__body {
    width: 50%;
    text-align: center;
  }
}

.email {
  display: flex;
  flex-direction: row;
  width: 1000px;
  height: 680px;
  // padding: 0px !important;
  margin-bottom: 150px;

  &__content {
    width: 50%;
    padding-left: 63px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-family: Montserrat;
      font-size: 25.5px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.6px;
      color: #343c61;
      margin: 0;
      padding-bottom: 40px;
    }

    &__registerInput {
      margin-bottom: 30px !important;
      padding-right: 60px !important;
    }

    &__container {
      display: flex;
      flex-direction: row;
      margin-top: 45px;

      &__LineButton {
        width: 135px;
        height: 50px;
        border-radius: 35px !important;
        border: solid 2px #f2b143 !important;
        font-size: 18px !important;
        font-family: Montserrat !important;
        margin-right: 20px !important;
        color: #e8883c !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        font-weight: bold;
      }
    }
  }

  &__background {
    padding: 30px;
    width: 50%;
    margin-left: -77px;
  }
}

@media screen and (max-width: 1200px) {
  .signupForm {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 680px;
    padding: 0px 0 !important;
    border-radius: 20px;
    background-color: ghostwhite;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .MuiDialog-paperScrollPaper-7 {
    width: calc(100% - 57px);
    max-height: calc(100% - 60px) !important;
  }

  .MuiDialog-paper-6 {
    margin: unset !important;
  }

  .signUp {
    width: 100%;
    // height: 590px;

    &__buttons {
      >button {
        width: 90%;
      }
    }
  }

  .email {
    width: 91%;
    height: unset;
    padding: 0 2.5%;

    // @media screen and (max-width: 825px) {
    //   // width: 100%;
    // }

    &__content {
      padding-left: 15px;
      padding-top: 40px;

      &__title {
        font-size: 25px;
      }

      &__registerInput {
        margin-bottom: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .MuiDialog-paperScrollPaper-7 {
    width: calc(100% - 20px);
  }

  .signupForm {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: unset;
    padding: 0px 0 !important;
    border-radius: 20px;
    background-color: ghostwhite;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .signUp {
    display: block;
    height: unset;
    padding: unset !important;
    width: 100%;

    &__buttons {
      width: 100%;
      border-right: unset;
      padding-bottom: unset;

      &__title {
        font-size: 5.5vw;
        margin-top: 50px;
        margin-bottom: 30px;
      }
    }

    &__form {
      width: 100%;
      margin-top: -25px;
      box-shadow: unset;
    }

    &__background {
      position: inherit;
      width: 100%;
    }
  }

  .email {
    display: block;
    height: 590px;
    width: 100% !important;

    &__content {
      width: 93% !important;
      // padding-left: 10px;
      // padding-right: 10px;

      &__title {
        text-align: center;
        font-size: 5vw;
      }

      &__registerInput {
        padding-right: unset !important;
      }

      &__container {
        justify-content: center;
      }
    }

    &__background {
      width: 100%;
      padding: 30px 0;
      margin-left: unset;
    }
  }
}

.registerPanel {
  padding: 0px;

  h1 {
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }

  .registerPanel-title {
    text-align: center;
    font-size: 20px;
  }

  .registerInput {
    width: 100%;
  }

  .formSubmitBtn {
    padding: 10px 20px;
    font-weight: bold;
    color: whitesmoke;
    background-color: #3053e4;
    border: 0px;

    &:hover {
      cursor: pointer;
    }
  }
}