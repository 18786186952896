.bookingStep {
  font-family: Montserrat;
  position: relative;
  padding-bottom: 150px;

  >p {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #232651;
  }

  >div {
    margin-left: 200px;
    z-index: 1;
  }

  >img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

.stepItem {
  font-family: Montserrat;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
  }

  >p {
    color: #232651;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-weight: bold;

    >span {
      color: #286ff9;
    }
  }
}

.stepItem11 {
  font-family: Montserrat;
  margin-bottom: 3px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  >span {
    width: 95px;
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(193, 203, 249, 0.3);
    left: -150px;
    text-align: center;
    color: #606e97;
  }

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
    fill: #c1cbf9;
  }

  >p {
    color: #c1cbf9;
    letter-spacing: 0.01em;
    font-size: 14px;
    font-weight: bold;

    >span {
      color: #286ff9;
    }
  }
}

.stepItem21 {
  font-family: Montserrat;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 372px;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(224, 232, 255, 0.7);
  padding: 5px 0;
  position: relative;

  >span {
    width: 95px;
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(193, 203, 249, 0.3);
    left: -155px;
    text-align: center;
    color: #606e97;
  }

  >svg {
    align-self: flex-start !important;
    margin-top: 1rem;
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
  }

  >div {
    padding-top: 0.5rem;

    >span {
      color: #232651;
      letter-spacing: 0.01em;
      font-size: 14px;
      font-weight: bold;

      >span {
        color: #286ff9;
      }
    }

    >p {
      color: #606e97;
      letter-spacing: 0.01em;
      font-size: 12px;
      max-width: 292px;
      font-weight: 500;
      font-family: Montserrat;
      margin-top: 0.5rem;
    }
  }

  &__card {
    border-radius: 10px;
    background-color: rgba(96, 110, 151, 0.05);
    max-width: 150px;
    padding: 6px 8px;
    color: #232651;
    position: relative;
    margin-top: 15px;

    p:first-child {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    p:nth-child(2) {
      margin-top: 0.5rem;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01;

      span:first-child {
        color: #fdb451;
      }

      span:nth-child(2) {
        color: #f48847;
      }
    }

    >div {
      position: absolute;
      top: -10px;
      right: -22px;
      width: 46px;
      height: 46px;
      background: linear-gradient(98.6deg, #fdb451 18.45%, #f48847 79.11%);
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      text-align: center;

      >p {
        font-size: 15px;
        margin: 0px;
        margin-bottom: 0px !important;

        >span {
          font-weight: bold;
        }
      }
    }
  }
}

.stepItem3 {
  font-family: Montserrat;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 372px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(244, 72, 72, 0.2);
  padding: 5px 0;
  position: relative;
  background-color: #fcfdfe;

  >span {
    width: 95px;
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 5px;
    border-radius: 20px;
    background-color: rgba(193, 203, 249, 0.3);
    left: -155px;
    text-align: center;
    color: #606e97;
  }

  >svg {
    width: 38px;
    height: 38px;
    margin-right: 20px;
    margin-left: 20px;
  }

  >div {
    padding-top: 0.5rem;

    >span {
      color: #232651;
      letter-spacing: 0.01em;
      font-size: 14px;
      font-weight: bold;
    }

    >p {
      color: #606e97;
      letter-spacing: 0.01em;
      font-size: 12px;
      max-width: 292px;
      font-weight: 500;
      line-height: 1.73;
      margin-top: 0.5rem;

      >span {
        color: #286ff9;
        text-decoration: underline;
      }
    }
  }
}

.MuiStepConnector-vertical {
  margin-left: 6px !important;
}

.MuiStepConnector-lineVertical {
  min-height: 75px !important;
}

.payment1 {
  width: 45%;
  padding-top: 10px;

  >div {
    // display: flex;
    justify-content: space-between;
    margin: 25px 0;

    input {
      width: 100%;
      display: block;
      margin: 9px 0 5px 0;
      font-size: 15.5px;
      letter-spacing: 1.5px;
      //   max-width: 400px;
      padding: 5px 0px;
      // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      //   rgba(0, 0, 0, 0.196078) 0px 1px 0px;
      border: 0;
      outline: 0;
      // border-radius: 5px;
      border-bottom: solid 1px #d9dcee;
      // background: #fafbff;
      // font-size: 18px;
      background: transparent;
      color: #596390;
      // letter-spacing: 0.15em;
    }

    label {
      // width: 45%;
      display: block;
      color: #596390;
      font-weight: bold !important;
      letter-spacing: 0.9px;
      font-size: 18px;
      margin-bottom: 45px;

      @media screen and (min-width: 768px) {
        // margin: 8px 5px;
      }

      .StripeElement {
        display: block;
        margin: 9px 0 5px 0;
        //   max-width: 400px;
        padding: 5px 0px;
        // letter-spacing: 0.125em;
        // box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        //   rgba(0, 0, 0, 0.196078) 0px 1px 0px;
        // border: 0;
        outline: 0;
        // border-radius: 5px;
        border-bottom: solid 1px #d9dcee;
        // background: #fafbff;
        font-size: 18px;
        color: #424770;
        letter-spacing: 0.15em;
        height: 21px;
      }

      // input {
      //   font-size: 18px !important;
      //   letter-spacing: 0.1em !important;
      // }
    }
  }
}

.cardcheck {
  width: 100%;

  &__text {
    font-size: 14.5px;
    font-weight: 300;
    line-height: 1.69;
    letter-spacing: 0.36px;
    text-align: center;
    color: #4c5788;
    margin: 0px auto;

    >span {
      color: #e7863c;
      font-weight: bold;
    }
  }
}