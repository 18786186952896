@import "~react-input-range/lib/css/index.css";

.form {
  >p {
    margin-top: 10px;
    font-size: 1.3rem;
  }
}

.name {
  &__title {
    display: flex;
    justify-content: center;
    margin-top: 100px !important;
    margin-bottom: 30px !important;
  }

  &__icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
    fill: #f2b143;
    margin-right: 25px;
  }

  &__gendertext {
    font-size: 18px !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
    line-height: 1 !important;
    letter-spacing: 0.9px;
    color: #596390 !important;
  }

  &__formgrid {
    margin-top: 30px !important;
    width: 100%;
    display: flex;
    justify-content: center;

    >div {
      flex-grow: 1;

      >div>label> {
        span:nth-child(2) {
          font-size: 1.5rem !important;
          font-family: Montserrat !important;
          font-weight: 500 !important;
          line-height: 1 !important;
          letter-spacing: 0.9px;
          color: #9ba2c9 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .name {
    &__formgrid>div>label {
      font-size: 21px !important;
      width: max-content !important;
    }

    &__formgrid>div>div {
      flex-direction: column !important;
    }
  }
}

.university {
  &__title {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 16px;
  }
}

.budget {
  &__title {
    display: flex;
    justify-content: center;
    margin-top: 125px;
    margin-bottom: 72px;
  }
}

.semester {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 22px;
  }

  &__description {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-align: center;
    color: #596390;
  }
}

.questions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    &__body {
      justify-content: center !important;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 18px;
  }

  &__description {
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.7px;
    text-align: center;
    color: #596390;
    margin-bottom: 40px;
  }

  &__answers {
    margin-bottom: 50px !important;
    width: 476px;
    border-radius: 10px;
    background-image: linear-gradient(136deg, #f2b143 1%, #e6813b 99%);
  }

  &__item {
    background-color: #f8f8f810;
    border-radius: 2px;
    border-left: 4px solid #fff;
  }

  &__itemFont {
    opacity: 1;
    font-family: Montserrat;
    font-size: 14.5px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.34;
    letter-spacing: 0.4px;
    text-align: left;
    color: #ffffff;
  }

  // &__inputForm {
  //   border-radius: 10px;
  //   box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
  //   background-color: #ffffff;
  // }

  &__input {
    margin-bottom: 50px !important;
    width: 416px;
    height: 550px;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
    background-color: rgb(255, 255, 255);
    margin: 0px;
    resize: none;
    border: none;
    font-family: Montserrat;
    font-size: 14.5px;
    font-weight: 300;
    letter-spacing: 0.7px;
    text-align: left;
    color: #596390;
  }
}

.input-range {
  height: 10rem;

  &__label {
    font-family: Montserrat;
    font-size: 15.5px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.94;
    letter-spacing: 0.8px;
    text-align: left;

    &--min {
      bottom: 5px;
      left: 10px;
      color: #9ba2c9;
    }

    &--max {
      bottom: 5px;
      right: 25px;
      color: #9ba2c9;
    }

    &--value {
      font-size: 18px;
      font-weight: 600;
      color: #596390;
      top: -6.8rem;
    }
  }

  &__track {
    background-color: #9ba2c9;
    height: 1px;

    &--background {
      margin-top: -0.5rem;
    }

    &--active {
      height: 3px;
      box-shadow: 0 0 78.1px 5.9px rgba(37, 60, 158, 0.24);
      background-color: #212ffd;
    }
  }

  &__slider {
    width: 23px;
    height: 23px;
    border: none;
    box-shadow: 0 0 78.1px 5.9px rgba(37, 60, 158, 0.24);
    background-color: #212ffd;
    margin-left: -11.5px;
    margin-top: -14.5px;
  }
}

.form {
  &__button {
    &__container {
      display: flex;
      justify-content: center;
      margin-top: 51px;
      margin-bottom: 56px;
    }
  }

  &__nextButton {
    &__text {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.9px;

      &--right {
        color: #ffffff;
      }

      &--left {
        color: #ffffff;
      }
    }
  }

  &__arrow {
    width: 17px;
    height: 14px;

    &--left {
      filter: invert(1);
      margin-right: 6px;
    }

    &--right {
      filter: invert(1);
      margin-left: 10px;
    }
  }
}

.daysGrid {
  display: flex;
  margin-top: 50px;
}

.daysNumber {
  padding-right: 10px;
  text-shadow: 6.9px 1.2px 57.7px rgba(0, 46, 245, 0.13);
  background-image: linear-gradient(124deg, #5980ff 1%, #0000ff 99%),
    linear-gradient(to bottom, #1e2bff, #1e2bff);
  font-family: Montserrat;
  font-size: 119.5px;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: left;
  color: #1e2bff;
  -webkit-background-clip: text;
  -background-clip: text;
  -webkit-text-fill-color: transparent;
}

.daysText {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.52;
  letter-spacing: 0.8px;
  text-align: left;
  color: #4c5788;
  margin-top: 35px;
  margin-left: 20px;
}

.circles {
  width: 223px;
  height: 223px;
  opacity: 0.45;
  box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
  border: solid 30px #e8e8e8;
  background-color: #ffffff;
}

.calendars {
  width: 100%;
  height: 530px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
}

.calendarTitle {
  flex-direction: row;
  display: flex;
  margin-bottom: 15px;

  &__icon {
    width: 35px;
    height: 40px;
    // fill: "linear-gradient(136deg, #f2b143 1%, #e6813b 99%)";
    fill: #f2b143;
    margin-right: 20px;
  }

  &__text {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: left;
    color: #4c5788;
    align-self: center;
  }
}

.react-calendar {
  width: 481px !important;
  height: 485px;
  border-radius: 10px;
  box-shadow: 0 0 27px 8.1px rgba(210, 215, 255, 0.21);
  background-color: #ffffff;
  border-width: 0px !important;
  padding: 25px;

  &__navigation__label {
    font-size: 23.5px !important;
    color: #596390 !important;
    font-weight: 600 !important;
  }

  &__tile {
    padding: 19px 0px !important;
    border-radius: 50px;
  }

  &__tile--active {
    box-shadow: 6.9px 1.2px 57.7px 4.3px rgba(0, 46, 245, 0.3);
    background-image: linear-gradient(124deg, #5980ff 1%, #0000ff 99%);
    background: linear-gradient(124deg, #5980ff 1%, #0000ff 99%) !important;
    color: white !important;
    border-radius: 50px;
    font-weight: 600 !important;
  }

  &__month-view {
    &__days {
      &__day--weekend {
        color: black;
      }

      &__day--neighboringMonth {
        color: #dee1f0 !important;
      }
    }

    &__weekdays {
      height: 45px;
      font-family: Montserrat !important;
      color: #596390 !important;
      font-size: 18px !important;
      border-radius: 23px;
      background-color: #fafafc;
      align-items: center;
    }
  }

  button {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: center;
    color: #596390;
  }

  button:hover {
    border-radius: 50px;
  }
}

abbr[title],
acronym[title] {
  text-decoration: none !important;
}

@media screen and (max-width: 600px) {
  .name {
    &__form {
      display: block !important;
    }

    &__formgrid {
      max-width: unset !important;
      margin-bottom: 20px !important;
    }
  }

  .budgetspin {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .semester {
    &__semesters {
      display: none !important;
    }

    &__semesterlist {
      display: none !important;
    }
  }

  .calendars {
    flex-direction: column;
    height: unset;

    &__cal {
      margin: 20px 0;
    }
  }

  .react-calendar {
    height: unset;

    &__tile {
      padding: 12px 0px !important;

      @media screen and (max-width: 450px) {
        padding: 9px 0px !important;
      }

      @media screen and (max-width: 450px) {
        padding: 6px 0px !important;
      }
    }

    &__month-view__weekdays {
      font-size: 10px !important;
    }

    &__navigation__label {
      font-size: 12.5px !important;
    }
  }

  .daysNumber {
    font-size: 70px;
  }

  .daysText {
    font-size: 20px;
    margin-left: unset;
    margin-top: 15px;
  }
}