.personInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__logo {
    width: 86px;
    fill: rgba(216, 66, 60, 1);
    margin-right: 5px;
  }

  &__university {
    margin: -25px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__imgItem {
    width: 111px;
    height: 111px;
    border-radius: 55px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 600px) {
  .personInfoName {
    font-size: 7vw;
  }
}